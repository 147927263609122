import Typography from '@/components/base/elements/Typography/Typography';
import {twMerge} from '@/stylesheets/twMerge';

import TableRow from './TableRow';
import {type ComparisonTableProps} from './types';

/**
 * Comparison table, originally from `src/components/plus/redesign`, based on
 * the `V01` brochureV2 ComparisonTable component.
 *
 * @see https://github.com/Shopify/brochure-iii/blob/a8dff9038b2a4e39891f4436b4413d50f0482229/src/components/plus/redesign/section/ComparisonTable/ComparisonTable.tsx
 *
 * @note Met with Thaira & Chien-Ju Dec. 15th and discussed plans to polish this
 * up from a design and code perspective, then move to base.
 */
export default function ComparisonTable({
  className,
  caption,
  colHeaders = [],
  rows,
  footer,
  headClassName,
  tbodyClassName,
  headHeaderClassName,
  rowClassName,
  firstRowClassName,
  lastRowClassName,
  rowHeadClassName,
  rowHeaderHiliteColor,
  rowCellClassName,
  rowCellHeaderClassName,
  rowCellHeaderWrapperClassName,
  rowCellCheckboxClassName,
  rowCellTextClassName,
  checkIconClassName,
  falsyIcon,
  tableContainerClassName,
  footerClassName,
}: ComparisonTableProps) {
  const blockRowGroupStyles = 'block md:table-row-group';

  /**
   * Primarily useful to override padding for the first and last table rows to
   * achieve more consistent vertical row spacing
   */
  const mergedFirstRowClassName = twMerge('md:pt-[1.4rem]', firstRowClassName);
  const mergedLastRowClassName = twMerge('md:pb-[1.4rem]', lastRowClassName);

  return (
    <div className={twMerge('container richtext', className)}>
      <div
        className={twMerge(
          'relative max-w-full overflow-auto rounded-3xl border border-shade-20',
          tableContainerClassName,
        )}
      >
        <table className="block md:table max-w-full default:border-collapse mx-auto w-full">
          <caption className="sr-only">{caption}</caption>
          {colHeaders.length > 0 && (
            <thead
              className={twMerge(
                'bg-black min-h-[3rem] text-lg md:text-body-lg',
                blockRowGroupStyles,
                headClassName,
              )}
            >
              <tr className="w-full flex md:table-row">
                {colHeaders.map((header, index) => {
                  return (
                    <th
                      key={`column-${index}`}
                      scope="col"
                      className={twMerge(
                        'py-2 md:py-5 px-5 md:px-9 text-white',
                        index === 0 && 'text-left w-full md:w-auto',
                        index > 0 && 'sr-only md:not-sr-only',
                        headHeaderClassName,
                      )}
                    >
                      {header}
                    </th>
                  );
                })}
              </tr>
            </thead>
          )}
          <tbody
            className={twMerge(
              'align-middle bg-[#DBE7D4]',
              blockRowGroupStyles,
              tbodyClassName,
            )}
          >
            {rows.map((row, index) => (
              <TableRow
                className={rowClassName}
                colHeaders={colHeaders}
                row={row}
                key={`row-${index}`}
                rowHeaderHiliteColor={rowHeaderHiliteColor}
                rowHeadClassName={twMerge(
                  index === 0 && mergedFirstRowClassName,
                  index === rows.length - 1 && mergedLastRowClassName,
                  rowHeadClassName,
                )}
                rowCellClassName={twMerge(
                  index === 0 && mergedFirstRowClassName, // `<th>
                  index === rows.length - 1 && mergedLastRowClassName,
                  rowCellClassName,
                )}
                rowCellHeaderWrapperClassName={rowCellHeaderWrapperClassName}
                rowCellHeaderClassName={rowCellHeaderClassName}
                rowCellCheckboxClassName={rowCellCheckboxClassName}
                rowCellTextClassName={rowCellTextClassName}
                checkIconClassName={checkIconClassName}
                falsyIcon={falsyIcon}
              />
            ))}
          </tbody>
        </table>
      </div>
      {footer && (
        <Typography
          className={twMerge('pt-6 px-1.5', footerClassName)}
          size="body-sm"
        >
          {footer}
        </Typography>
      )}
    </div>
  );
}
