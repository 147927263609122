import Collapse from '@/components/base/elements/Icon/icons/Collapse';
import {twMerge} from '@/stylesheets/twMerge';
import {IconEnum} from '@/enums';
import Icon from '@/components/base/elements/Icon/Icon';

import type {ComparisonTableProps, Row} from './types';

type TableRowProps = Omit<ComparisonTableProps, 'rows' | 'caption'> & {
  row: Row;
};

export default function TableRow({
  className,
  colHeaders,
  row,
  rowHeadClassName,
  rowHeaderHiliteColor,
  rowCellClassName,
  rowCellHeaderWrapperClassName,
  rowCellHeaderClassName,
  rowCellCheckboxClassName,
  rowCellTextClassName,
  checkIconClassName,
  falsyIcon,
}: TableRowProps) {
  const FalsyIcon = () =>
    falsyIcon ? falsyIcon : <Collapse className="w-[28px] text-[#ccc]" />;
  const sharedCellItemStyles = 'flex justify-center w-1/3 md:w-auto';
  const sharedCellHeaderStyles =
    'block text-base font-thin leading-8 md:hidden';

  return (
    <tr
      className={twMerge(
        'border-shade-20 w-full flex flex-col md:table-row',
        className,
      )}
    >
      {row.map((cell, index) => {
        if ('header' in cell) {
          const secondaryText =
            cell.headerHilite || cell.headerSubText || undefined;
          const secondaryTextStyles = twMerge(
            cell.headerHilite &&
              'rounded-xl uppercase text-xs font-bold bg-[#C3D2BB] py-1 px-2 ml-1',
            rowHeaderHiliteColor,
            cell.headerSubText && 'block font-normal',
          );

          return (
            cell.header && (
              <th
                key={`cell-${index}`}
                scope="row"
                className={twMerge(
                  'w-full py-2 px-5 text-left text-base font-bold',
                  'md:box-content md:font-medium md:w-auto md:px-9 md:py-0 md:text-xl md:h-[4rem]',
                  rowHeadClassName,
                )}
              >
                {/* Header content can have HTML, such as `<sup>1</sup>` */}
                <span dangerouslySetInnerHTML={{__html: cell.header}} />
                {secondaryText && (
                  <span className={secondaryTextStyles}>{secondaryText}</span>
                )}
              </th>
            )
          );
        }

        return (
          <td
            key={`cell-${index}`}
            colSpan={cell.colSpan || 1}
            className={twMerge(
              'py-1 md:p-0 px-5 md:px-0 md:px-11 flex md:table-cell bg-white justify-between items-center md:text-center',
              index === 1 && 'pb-0 pt-2.5', // `<th>` is index 0
              index === row.length - 1 && 'pt-0 pb-2.5',
              rowCellClassName,
            )}
          >
            {cell.colSpan && (
              <div
                className={twMerge(
                  'flex flex-col gap-4',
                  rowCellHeaderWrapperClassName,
                )}
              >
                {Array.from({length: cell.colSpan}).map(
                  (_, i) =>
                    colHeaders?.[i + 1] && (
                      <span
                        key={`cellHead-${i}`}
                        className={twMerge(
                          sharedCellHeaderStyles,
                          rowCellHeaderClassName,
                        )}
                      >
                        {colHeaders[i + 1]}
                      </span>
                    ),
                )}
              </div>
            )}
            {!cell.colSpan && colHeaders?.[index] && (
              <span
                className={twMerge(
                  sharedCellHeaderStyles,
                  rowCellHeaderClassName,
                )}
              >
                {colHeaders[index]}
              </span>
            )}
            {typeof cell.checkbox === 'boolean' && (
              <div
                className={twMerge(
                  sharedCellItemStyles,
                  'items-center',
                  rowCellCheckboxClassName,
                )}
              >
                {cell.checkbox === true && (
                  <Icon
                    className={twMerge(
                      'w-7 md:w-[46px] text-shade-90',
                      checkIconClassName,
                    )}
                    icon={IconEnum.Check}
                  />
                )}
                {cell.checkbox === false && <FalsyIcon />}
              </div>
            )}
            {typeof cell.text === 'string' && (
              <span
                className={twMerge(
                  sharedCellItemStyles,
                  'text-xs',
                  rowCellTextClassName,
                )}
                dangerouslySetInnerHTML={{__html: cell.text}}
              />
            )}
          </td>
        );
      })}
    </tr>
  );
}
